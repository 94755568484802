import "./assets/css/App.css";
import "./assets/css/Wcag.css";

import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import Logo from "./assets/image/logo.png";
import imageCity from "./assets/image/city.jpg";

import Start from "./pages/Start";
import News from "./pages/News";
import NewsContent from "./pages/NewsContent";
import NewsContent1 from "./pages/NewsContent1";
import Association from "./pages/Association";
import Contact from "./pages/Contact";
import Partners from "./pages/Partners";
import Competitions from "./pages/Competitions";
import CompetitionsContent from "./pages/CompetitionsContent";

function App() {
	let navigate = useNavigate();
	useEffect(() => {
		window.scrollTo(0, 0);
		(() => {
			let elements;
			let windowHeight;

			function init() {
				elements = document.querySelectorAll(".hidden:not(.popup)");
				windowHeight = window.innerHeight;
			}

			function checkPosition() {
				elements.forEach((element) => {
					let positionFromTop = element.getBoundingClientRect().top;

					if (positionFromTop - windowHeight <= 0) {
						element.classList.add("fade-in-element");

						element.classList.remove("hidden");
					}
				});
			}

			window.addEventListener("scroll", checkPosition);
			window.addEventListener("resize", init);
			window.addEventListener("touchmove", checkPosition);

			init();
			checkPosition();
		})();
	}, [navigate]);
	const changeContrast = (e) => {
		if (e.target.nodeName === "LI") {
			let rootVar = document.querySelector(":root");

			if (e.target.id === "contrastStandard") {
				rootVar.style.removeProperty("--font-color");
				rootVar.style.removeProperty("--bg-color");
				rootVar.style.removeProperty("--font-color-second");
			}
			if (e.target.id === "contrastWhite") {
				rootVar.style.setProperty("--font-color", "#fff");
				rootVar.style.setProperty("--bg-color", "#000");
				rootVar.style.setProperty("--font-color-second", "#fff");
			}
			if (e.target.id === "contrastBlack") {
				rootVar.style.setProperty("--font-color", "#000");
				rootVar.style.setProperty("--bg-color", "#ff0");
				rootVar.style.setProperty("--font-color-second", "#000");
			}
			if (e.target.id === "contrastYellow") {
				rootVar.style.setProperty("--font-color", "#ff0");
				rootVar.style.setProperty("--bg-color", "#000");
				rootVar.style.setProperty("--font-color-second", "#ff0");
			}
		}
	};
	const changeFontSize = (e) => {
		if (e.target.nodeName === "LI") {
			let rootVar = document.querySelector(":root");

			if (e.target.id === "fontSizeStandard") {
				rootVar.style.setProperty("--font-multiplier", 1.0);
			}
			if (e.target.id === "fontSizePluse") {
				rootVar.style.setProperty("--font-multiplier", 1.05);
			}
			if (e.target.id === "fontSizePlusePluse") {
				rootVar.style.setProperty("--font-multiplier", 1.15);
			}
		}
	};
	const toogleHamburger = () => {
		let sidebar = document.querySelector(".sidebar");
		let body = document.querySelector("body");
		let hamburger = document.querySelector("#menu");

		sidebar.classList.toggle("is_active");
		body.classList.toggle("hidden-overflow");
		hamburger.classList.toggle("is_active");
	};
	return (
		<>
			<div className="sidebar">
				<ul
					onClick={() => toogleHamburger()}
					className="fade-in-element">
					<li>
						<Link
							to="/aktualnosci"
							className="link--hover-effect">
							Aktualności
						</Link>
					</li>
					<li>
						<Link
							to="/konkursy"
							className="link--hover-effect">
							Konkursy
						</Link>
					</li>
					<li>
						<Link
							to="/partnerzy"
							className="link--hover-effect">
							Partnerzy
						</Link>
					</li>
					<li>
						<Link
							to="/stowarzyszenie"
							className="link--hover-effect">
							Stowarzyszenie
						</Link>
					</li>
					<li>
						<Link
							to="/kontakt"
							className="link--hover-effectg">
							Kontakt
						</Link>
					</li>
				</ul>
				<img
					src={imageCity}
					alt=""
				/>
			</div>
			<nav>
				<div className="logo fade-in-element-left">
					<Link to="/">
						<img
							title="Stowarzyszenie 'Rzeszów to moje miasto'"
							src={Logo}
							alt="Stowarzyszenie 'Rzeszów to moje miasto'"
						/>
					</Link>
				</div>
				<ul className="fade-in-element">
					<li>
						<Link
							to="/aktualnosci"
							className="link--hover-effect">
							<i className="fa-solid fa-house"></i>Aktualności
						</Link>
					</li>
					<li>
						<Link
							to="/konkursy"
							className="link--hover-effect">
							<i className="fa-solid fa-gift"></i>Konkursy
						</Link>
					</li>
					<li>
						<Link
							to="/partnerzy"
							className="link--hover-effect">
							<i className="fa-solid fa-handshake"></i>Partnerzy
						</Link>
					</li>
					<li>
						<Link
							to="/stowarzyszenie"
							className="link--hover-effect">
							<i className="fa-solid fa-circle-info"></i>Stowarzyszenie
						</Link>
					</li>
					<li>
						<Link
							to="/kontakt"
							className="link--hover-effect">
							<i className="fa-solid fa-address-book"></i>Kontakt
						</Link>
					</li>
				</ul>
				<div
					id="clickMenu"
					onClick={() => toogleHamburger()}>
					<div id="menu"></div>
				</div>
			</nav>

			<Routes>
				<Route
					path="/"
					element={<Start />}
				/>
				<Route
					path="/konkursy"
					element={<Competitions />}
				/>
				<Route
					path="/konkursy/:id"
					element={<CompetitionsContent />}
				/>
				<Route
					path="/aktualnosci"
					element={<News />}
				/>
				<Route
					path="/aktualnosci/0"
					element={<NewsContent />}
				/>
				<Route
					path="/aktualnosci/1"
					element={<NewsContent1 />}
				/>
				<Route
					path="/partnerzy"
					element={<Partners />}
				/>
				<Route
					path="/stowarzyszenie"
					element={<Association />}
				/>
				<Route
					path="/kontakt"
					element={<Contact />}
				/>
			</Routes>
			<footer>
				<section>
					<ul>
						<li>
							<Link
								to="/aktualnosci"
								className="link--hover-effect">
								<i className="fa-solid fa-house"></i>Aktualności
							</Link>
						</li>
						<li>
							<Link
								to="/konkursy"
								className="link--hover-effect">
								<i className="fa-solid fa-gift"></i>Konkursy
							</Link>
						</li>
						<li>
							<Link
								to="/partnerzy"
								className="link--hover-effect">
								<i className="fa-solid fa-handshake"></i>Partnerzy
							</Link>
						</li>
						<li>
							<Link
								to="/stowarzyszenie"
								className="link--hover-effect">
								<i className="fa-solid fa-circle-info"></i>Stowarzyszenie
							</Link>
						</li>
						<li>
							<Link
								to="/kontakt"
								className="link--hover-effect">
								<i className="fa-solid fa-address-book"></i>Kontakt
							</Link>
						</li>
					</ul>
					<div className="logo">
						<Link to="/">
							<img
								title="Stowarzyszenie 'Rzeszów to moje miasto'"
								src={Logo}
								alt="Stowarzyszenie 'Rzeszów to moje miasto'"
							/>
						</Link>
					</div>
					<ul>
						<li>
							<span>KRS:</span>0000667041
						</li>
						<li>
							<span>NIP:</span>8133740154
						</li>
						<li>
							<span>REGON:</span>366739322
						</li>
						<li>
							<span>Data rejestracji:</span>8 marca 2017r.
						</li>
						<li>
							<span>Adres siedziby:</span> ul. Adama Matuszczaka 7,
							<br /> 35-084 Rzeszów
						</li>
					</ul>
				</section>
				<div className="wcag">
					<div>
						<h4>Kontrast: </h4>
						<ul
							className="contrast"
							onClick={(e) => {
								changeContrast(e);
							}}>
							<li
								title="Standardowy kontrat"
								id="contrastStandard">
								A
							</li>
							<li
								title="Zmień kontrast"
								id="contrastWhite">
								A
							</li>
							<li
								title="Zmień kontrast"
								id="contrastBlack">
								A
							</li>
							<li
								title="Zmień kontrast"
								id="contrastYellow">
								A
							</li>
						</ul>
						<h4>Tekst: </h4>
						<ul
							className="text"
							onClick={(e) => {
								changeFontSize(e);
							}}>
							<li
								title="Standardowy tekst"
								id="fontSizeStandard">
								A
							</li>
							<li
								title="Powiększ tekst 1.2x"
								id="fontSizePluse">
								A+
							</li>
							<li
								title="Powiększ tekst 1.5x"
								id="fontSizePlusePluse">
								A++
							</li>
						</ul>
					</div>
				</div>
			</footer>
		</>
	);
}

export default App;
