import "../assets/css/NewsContent.css";



export default function NewsContent1() {
	

	return (
		<>
			<main className="news-content--box">
				<div className="text_container">
					<h1 className="hidden">Wspomnienie o śp. Ryszardzie Lechforowiczu </h1> <hr />
				
					<p className="hidden">
                    Z głębokim smutkiem informujemy o śmierci Ryszarda Lechforowicza, zasłużonego mieszkańca Rzeszowa, przewodnika, redaktora, kolportera lokalnej prasy (w tym „Echa Rzeszowa”), który przez wiele lat był związany z naszym Stowarzyszeniem. Pan Ryszard był nie tylko pasjonatem historii Rzeszowa, ale także oddanym edukatorem, który  
z zaangażowaniem przybliżał mieszkańcom i młodzieży bogatą historię naszego miasta.  

Jego wkład w rozwój kulturalny i edukacyjny Rzeszowa jest nieoceniony. W ramach cyklicznego konkursu <strong>„Rzeszów – to moje miasto”</strong> Pan Ryszard prowadził liczne wycieczki, dzieląc się swoją wiedzą i miłością do miasta. To wieloletnie zaangażowanie się w jego bieżące sprawy było inspiracją dla kilku pokoleń rzeszowiaków. 

Urodzony we Lwowie w 1939 roku, angażował się nie tylko w działalność naszego Stowarzyszenia, ale był także członkiem Światowego Stowarzyszenia Zabużan, Kresowian, Repatriantów, Spadkobierców, Rodzin, Następców Prawnych, Sympatyków z siedzibą  
w Rzeszowie, Towarzystwa Przyjaciół Rzeszowa, Stowarzyszenia „Rzeszów to moje miasto”. Niejednokrotnie był swoistym kronikarzem życia Rzeszowa (jako autor kilkudziesięciu artykułów), ale także dużo pisał o regionalnym sporcie – motocyklowym, kolarstwie. 

Ryszard Lechforowicz pozostanie w naszej pamięci jako człowiek o wielkim sercu, który zawsze chętnie dzielił się swoją wiedzą i doświadczeniem. Utrwalał w pamięci ludzi, wydarzenia, ważne dla kształtowania tradycji i oblicza współczesnego Rzeszowa. 

Niech spoczywa w pokoju! 

 
					</p>
					<hr />
                </div>
			</main>
		</>
	);
}
